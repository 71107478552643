<template>
	<div id="horse_infos">
		<div class="row no-gutters">
			<div class="col">
				<i>
					{{ horseSexe }}, {{ formattedHorseAge }}, {{ horseRobe }}, {{ horseRace }}
					<span v-if="presence">
						- <font-awesome-icon :icon="['far', 'map-marker-alt']" />
						<router-link :to="{ name: 'mouvementResidence', params: { intralocation_id: presence.mouvement_lieu }}" v-if="presence && presence.mouvement_lieu">
							 / {{ presence.mouvement_label }}
						</router-link>
						<router-link :to="{ name: 'mouvementResidence', params: { intralocation_id: horseIntraLocation.mouvement_intra_location.intralocation_id }}" v-if="horseIntraLocation && horseIntraLocation.mouvement_intra_location">
							 / {{ horseIntraLocation.mouvement_intra_location.intralocation_label }}
						</router-link>
					</span>
				</i>
				<br>
				<span v-if="horse.horse_interne"> {{ horse.horse_interne }} - </span>
				<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.pere.horse_id }}" v-if="horse.pere">
					{{ horse.pere.horse_nom }}
				</router-link>
				<span v-else>{{ horse.pedigree.pere }}</span>
				<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.mere.horse_id }}" v-if="horse.mere">
					/ {{ horse.mere.horse_nom }}
				</router-link>
				<span v-else>/ {{ horse.pedigree.mere }}</span>
			</div>
			<div v-if="hasReproRights">
				<template v-if="horse.actual_status">
					<span class="col-auto" v-for="(status, idx) in horse.actual_status" :key="idx">
						<template v-if="status">
							{{ horse.actual_season[idx].season_label }} : 
							<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: status.horse_id }}">
								{{ status.horse_nom }}
							</router-link> 
							- 
							<!-- <router-link v-if="suitee_horse_ids[idx]" :to="{ name: 'HorseFicheInfo', params: { horse_id: suitee_horse_ids[idx] }}">
								{{ getTradStatus(status.status) }}
							</router-link>
							<span v-else> -->
								{{ getTradStatus(status.status) }} 
							<!-- </span> -->
							<span v-if="status.donneuse_id && status.donneuse_nom">
								({{$t("horse.embryon_de")}} 
								<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: status.donneuse_id }}">
									{{ status.donneuse_nom }}
								</router-link> 
								)
							</span>
						</template>
					</span>
				</template> 
				<br if="horse.actual_status && horse.previous_status" />
				<span class="col-auto" v-if="horse.previous_status">{{ horse.previous_season.season_label }} :
					<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.previous_status.horse_id }}">
						{{ horse.previous_status.horse_nom }}
					</router-link>
					 - 
					<router-link v-if="previous_suitee_horse_id" :to="{ name: 'HorseFicheInfo', params: { horse_id: previous_suitee_horse_id }}">
						{{ horse_previous_status }}
					</router-link>
					<span v-else> {{ horse_previous_status }} </span> 
				</span>
			</div>
			<div v-if="!horse_readonly" class="col-auto pl-2 pl-sm-0">
				<b-button @click="interactDeleteHorse" variant="secondary">
					<font-awesome-icon :icon="['fal', 'trash-alt']" />
				</b-button>
			</div>
		</div>
		<b-modal ref="modal_delete_horse" hide-footer>
			<template v-slot:modal-title>
				{{ $t("modal.horse.suppression") }}
			</template>

			<p class="text-center">{{ $t("modal.horse.want_to_do") }}</p>

			<div class="row">
				<div class="col-12 d-flex justify-content-center">
					<b-button variant="primary" @click="applyDeleteOrArchiveHorse(true)">
						{{ $t("modal.horse.final_deletion") }}
						<font-awesome-icon v-if="processing_delete" :icon="['fas', 'spinner']" pulse />
					</b-button>
					<b-button variant="primary" class="mx-2" @click="applyDeleteOrArchiveHorse(false)">
						{{ $t("modal.horse.archive_horse") }}
						<font-awesome-icon v-if="processing_archive" :icon="['fas', 'spinner']" pulse />
					</b-button>
					<b-button variant="secondary" @click="$refs.modal_delete_horse.hide()">{{ $t("global.annuler") }}</b-button>
				</div>
			</div>
		</b-modal>

	</div>
</template>


<script type="text/javascript">
	import Horse from '@/mixins/Horse.js'
	import Common from '@/assets/js/common.js'
	import ModalConfirm from "GroomyRoot/components/Modals/ModalConfirm"

	export default {
		props:[
			'horse',
			'presence',
			'horseIntraLocation',
			'horse_readonly'
		],
		mixins: [
			Horse
		],
		components: {
            ModalConfirm
		},
		data () {
			return {
				family_infos_needed: false,
				suitee_horse_ids: [],
				previous_suitee_horse_id: null,
				raw_families: null,
				parenthesisRegExp: /\(([^)]+)\)/,
				processing_delete: false,
				processing_archive: false,
			}
		},
		mounted() {
			this.init_component()
		},
		computed: {
			// Retourne le sexe complet dans la langue actuelle
			horseSexe: function() {
				return this.horse.sexe_label
			},
			horseRace: function() {
				return this.horse.race_label
			},
			horseRobe: function() {
				return this.horse.robe_label
			},
			// horse_status: function() {
			// 	return this.horse.actual_status ? Common.translateSeasonMareStatus(this.horse.actual_status) : null
			// },
			horse_previous_status: function() {
				return this.horse.previous_status && this.horse.previous_status.status ? Common.translateSeasonMareStatus(this.horse.previous_status.status) : null
			},
			formattedHorseAge() {
				// Si cheval < 1 an, on affiche la date de naissance, sinon l'âge
				let age = this.horse.horse_age
				let birthdate = this.horse.horse_datenaissance
				if (new Date(birthdate).getFullYear() !== new Date().getFullYear()) return age + this.$t("horse.ans") 
				const options = {
					day: "numeric",
					month: "long"
				}
				let englishDateTail = "";
				const day = new Date(birthdate).getDate()
				if (this.$i18n.locale() == 'en'){
					if (day > 3 && day < 21){
						englishDateTail = "th"
					} else {
						switch (day % 10){
							case 1:  englishDateTail = "st"
							break;
							case 2:  englishDateTail = "nd"
							break;
							case 3:  englishDateTail = "rd"
							break;
							default: englishDateTail = "th";
						}
					}
				}
				return this.$t("horse.foal_born") + birthdate.toLocaleDateString(this.$i18n.locale(), options) + englishDateTail
			},
			hasReproRights() {
				return this.$store.state.userAccess.hasBreedingAccess
			}
		},
		methods:{
			async init_component() {

				this.raw_families = null

				// VV A DECOMMENTER SI ON DECIDE DE L'ACTIVER POUR CAS EXTREME DE POULINAGE D'UNE SAILLIE LA SAISON ACTUELLE

				// On regarde si jument suitée dans chaque status, si oui on va chercher sa production
				// if (this.horse.actual_status){
					
				// 	for await (const [idx, status] of this.horse.actual_status.entries()) {
				// 		if(status && status.includes('Suitée')){ // Si jument suitée
				// 			if (this.raw_families == null) this.raw_families = await this.getHorseChildren(this.horse.horse_id)
				// 			this.raw_families.forEach((family) => { // Pour chacun de ses enfants
				// 				if(family.enfant.horse_datenaissance.toString() == this.formatDateFromStatus(status).toString()){
				// 					// Le poulain né du suitée est trouvé grâce à sa date de naissance
				// 					this.suitee_horse_ids[idx] = family.enfant.horse_id
				// 				}
				// 			})
				// 		} else {
				// 			this.suitee_horse_ids[idx] = null
				// 		}
				// 	}
				// } 


				// Pareil mais pour previous saison
				if (this.horse.previous_status && this.horse.previous_status.status && this.horse.previous_status.status.includes('Suitée')){
					// On obtient les enfants si ils n'ont pas déjà été obtenus
					if (this.raw_families == null) this.raw_families = await this.getHorseChildren(this.horse.horse_id)
					this.raw_families.forEach((family) => {
						if(family.enfant.horse_datenaissance.toString() == this.formatDateFromStatus(this.horse.previous_status.status).toString()){
							// Le poulain né du suitée est trouvé grâce à sa date de naissance
							this.previous_suitee_horse_id = family.enfant.horse_id
						}
					})
				}

			},
			// Appel de la sous-fenêtre de suppression du cheval
            interactDeleteHorse(data) {
				this.$refs['modal_delete_horse'].show()
            },
			// Convertir une date format DD/MM/YYYY au format Date()
			formatDateFromStatus(date){
				if (!date) return
				const dateString = this.parenthesisRegExp.exec(date)[1]
				const dateParts = dateString.split("/");
				return  new Date(+"20" + dateParts[2], dateParts[1] - 1, +dateParts[0]); 
			},
            // Appliquer la suppression ou l'archivage du cheval
			async applyDeleteOrArchiveHorse(deleteHorse) {	
				this.processing_archive = !deleteHorse
				this.processing_delete = deleteHorse
				
				deleteHorse ? await this.devalideHorses(this.horse.horse_id) : await this.archiveHorses([this.horse.horse_id])
				this.$router.replace({ name: 'horseListe' })
				this.$refs['modal_delete_horse'].hide()

				this.processing_archive = false
				this.processing_delete = false
			},
			getTradStatus(status) {
				return Common.translateSeasonMareStatus(status)
			}
		},
		watch: {
			'horse.previous_status' (val) {
				this.init_component()
			},
			'horse.actual_status' (val) {
				this.init_component()
			}
		}
	}
</script>
